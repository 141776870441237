
import { ref, watch, reactive, onMounted } from "vue";
import { ContentLoader } from 'vue-content-loader';
import useAlert from "@/composables/Alert.ts";
import useEmitter from '@/composables/Emmiter'
import { hideModal } from '@/core/helpers/dom';
import { storeFiltroUsuario } from "@/services/UsuarioService";
import { useStore } from 'vuex';

export default {
  name: "modal-filtrousuario",

  props: {
    id: {
      type: String,
      required: true,
    },
    usuario: {
      type: Object,
      required: true,
    },
    concessionarias: {
      type: Array,
      required: true,
    }
  },

  components: {
    ContentLoader
  },

  emits: ["atualizar"],

  setup(props, {emit}) {
    const store = useStore();
    const usuarioModal: any = ref({});
    const listaConcessionarias: any = ref({});
    const codConcessionariaSelected:any =ref({});
    const { showTimeAlert } = useAlert();
    let formUsuario:any = reactive({
      concessionaria: "",
    })
    const loading = ref(false); //variavel para controlar o botao de enviar para enviando.. (enquanto a requisição é efetuada ao backend)

    onMounted(() => {
      if (store.getters.concessionariasList.length > 0) {
        trataConcessionarias();
      }
    });

    store.watch(() => store.getters.concessionariasList, () => {
      trataConcessionarias();
    }, {deep: true});

    watch(() => props.concessionarias, () => {
      trataConcessionarias();
    })

    async function trataConcessionarias() {
      const auxConcessionariasUsuarioRef = props.concessionarias.map(concessionaria => concessionaria.codConcessionaria);
      
      listaConcessionarias.value = store.getters.concessionariasList.map((concessionaria: any) => {
        const option = {
          text: concessionaria.nomeFantasia,
          value: concessionaria.codConcessionaria,
          disabled: auxConcessionariasUsuarioRef.includes(Number(concessionaria.codConcessionaria))
        };

        return option;
      }).sort((concessionariaA, concessionariaB) => {
        if (!concessionariaA.disabled && concessionariaB.disabled) {
          return -1;
        }
        if (!concessionariaB.disabled && concessionariaA.disabled) {
          return 1;
        }

        if (concessionariaA.disabled && !concessionariaA.disabled) {
          if (concessionariaA.text < concessionariaB.text){
            return -1;
          }
          if (concessionariaA.text > concessionariaB.text){
            return 1;
          }
          return 0;
        }

        return 0;
      });
    }

     const formRef = ref<null | HTMLFormElement>(null);

     const submit = () => {
        const postData = {
          codConcessionaria: formUsuario.concessionaria,
          codUsuario: props.usuario.codUsuario
        }
        
        loading.value = true;
        storeFiltroUsuario(postData).then(() => {
          formUsuario.concessionaria = "";
          showTimeAlert("Concessionária adicionada ao usuário com sucesso");
          emit("atualizar"); 
          closeModal();
        }).catch((e) => {
          showTimeAlert("Não foi possível completar a solicitação", 'error');
        }).finally(() => {
            loading.value = false;
        });
      } 

    async function closeModal(){
      const refModal = document.getElementById(props.id);
			hideModal(refModal);
    }
    
    return {
      usuarioModal,
      listaConcessionarias,
      codConcessionariaSelected,
      formUsuario,
      formRef,
      submit,
      closeModal,
      loading
    }
  }
}
