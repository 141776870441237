
import 'cropperjs/dist/cropper.css';
import {ContentLoader} from 'vue-content-loader';
import { defineComponent, onMounted, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import useAlert from "@/composables/Alert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import AdicionarFiltroUsuario from "./components/AdicionarFiltroUsuario.vue";
import { getView, filtroList, deleteFiltroUsuario } from "@/services/UsuarioService";
import { useSelectedUsuarioStore } from "@/pinia/crudUsuario/useUsuarioStore"

export default defineComponent({
  name: "visualizarConcessionariaUsuario",

  components: {
    ContentLoader,
    AdicionarFiltroUsuario
  },

  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const route = useRoute();
    const usuarioStore = useSelectedUsuarioStore();
    const codUsuario = usuarioStore.getSelectedUsuario;
    const { showTimeAlert } = useAlert();
    const router = useRouter();
    const codNivel :any = ref([])
    let loading = ref(false);
    let loadingBtnRemove = ref(false);
    const infoUsuario = ref({
      nome: ""
    });

    const concessionariasUsuario:any = ref([]);

    onMounted(async () => {
      if(codUsuario) {
        getUsuario();
        filtrosUsuario();
      } 
    });

    async function getUsuario(){
      const response = await getView(codUsuario);
      infoUsuario.value = response;
    }

    async function filtrosUsuario(){
        loading.value = true;
        loadingBtnRemove.value = true;

        const response = await filtroList(codUsuario)
        concessionariasUsuario.value = response;
        loading.value = false;

        setTimeout(() => {
          loadingBtnRemove.value = false;
        }, 2000);

    }

    function removerAcessoConcessionaria(concessionariaId) {
      Swal.fire({
        title: "Deseja remover o acesso a essa concessionária?",
        showCancelButton: true,
        confirmButtonText: "Remover acesso",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {      
          loadingBtnRemove.value = true;

          deleteFiltroUsuario(concessionariaId).then(() => {
            showTimeAlert("Acesso a concessionária removido com sucesso");
            filtrosUsuario();
          }).catch(e => {
            showTimeAlert("Não foi possível completar a solicitação", "error");
          }).finally(() => {
            loadingBtnRemove.value = false;
          });
        }
      });
    } 

    function goTo(url) {
      router.push(url);
    }

    return {
      goTo,
      loading,
      loadingBtnRemove,
      formRef,
      codUsuario,
      codNivel,
      concessionariasUsuario,
      filtrosUsuario,
      infoUsuario,
      removerAcessoConcessionaria,
    };
  },
});
